import { GenericError } from './genericError';
import { ITypedErrorDescription } from './types';

/**
 * Ошибка таймаута. Должна использоваться, когда нужно выбросить исключение по таймауту.
 *
 * @export
 * @class TimeoutError
 * @extends {GenericError}
 */
export class TimeoutError extends GenericError {
  public constructor({ message, domain, details }: ITypedErrorDescription) {
    super({
      type: 'TimeoutError',
      message,
      domain,
      details,
    });

    Object.setPrototypeOf(this, TimeoutError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TimeoutError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
