import { NotFoundError, ValidationError } from '@cian/peperrors/shared';

import type { IManifest, IMicrofrontendManifest, IMicrofrontendIdentity } from '../shared/types';
import type { IBrowserRegistry } from '../browser/internal/registry/types';

import { Microfrontend } from './Microfrontend/Microfrontend';

interface IMicrofrontends {
  [location: string]: {
    [version: string]: Microfrontend;
  };
}

export class Registry implements IBrowserRegistry {
  private microfrontends: IMicrofrontends = {};

  public init({ microfrontends }: IManifest) {
    for (const microfrontendManifest of microfrontends) {
      this.createMicrofrontend(microfrontendManifest);
    }
  }

  public getMicrofrontend({ mcs, version }: IMicrofrontendIdentity) {
    const instances = this.microfrontends[mcs];

    if (!instances) {
      throw new NotFoundError({
        message: `Microfrontend ${mcs} not found`,
        domain: 'mf-registry.cdn.Registry.getMicrofrontend',
        details: {
          mcs,
          version,
          instances: Object.keys(this.microfrontends),
        },
      });
    }

    const instance = instances[version];

    if (!instance) {
      throw new NotFoundError({
        message: `Microfrontend ${mcs} version ${version} not found`,
        domain: 'mf-registry.cdn.Registry.getMicrofrontend',
        details: {
          mcs,
          version,
          versions: Object.keys(instances),
        },
      });
    }

    return instance;
  }

  private createMicrofrontend(microfrontendManifest: IMicrofrontendManifest) {
    const { identity, children } = microfrontendManifest;
    const { mcs, version } = identity;
    this.microfrontends[mcs] = this.microfrontends[mcs] || {};

    if (this.microfrontends[mcs][version]) {
      throw new ValidationError({
        message: `Microfrontend ${location} version ${version} already registered`,
        domain: 'mf-registry.cdn.Registry.createMicrofrontend',
        details: {
          identity: JSON.stringify(identity),
          versions: [...Object.keys(this.microfrontends[mcs])],
        },
      });
    }

    this.microfrontends[mcs][version] = new Microfrontend({
      registry: this,
      manifest: microfrontendManifest,
      children,
    });
  }
}
