/* eslint-disable @typescript-eslint/no-explicit-any */

import { NotFoundError } from '@cian/peperrors/shared';

import type { IMicrofrontendIdentity } from '../../../shared/types';
import type { IAPIParameters, IAPIRuntime, IAPIRuntimeImage, IAPIRuntimeMethod } from '../../../browser/types';

import { cloneDeep } from '../../../shared/internal/helpers/cloneDeep';

export class APIRuntime implements IAPIRuntime {
  public readonly image: IAPIRuntimeImage;

  private readonly identity: IMicrofrontendIdentity;

  public constructor(image: IAPIRuntimeImage, identity: IMicrofrontendIdentity) {
    this.image = image;
    this.identity = identity;
  }

  public async call<R, T extends IAPIParameters>(path: string, parameters: T): Promise<R> {
    const method = this.resolve(path);

    const result = await method.handler(this.image.context, cloneDeep(parameters));

    return cloneDeep(result) as R;
  }

  private resolve(path: string): IAPIRuntimeMethod {
    for (const method of this.image.methods) {
      if (method.path === path) {
        return method;
      }
    }

    throw new NotFoundError({
      message: `Method for path ${path} not found`,
      domain: 'mf-registry.cdn.APIRuntime.resolve',
      details: {
        microfrontend: JSON.stringify(this.identity),
        path,
      },
    });
  }
}
