import type { IMicrofrontendIdentity } from '../types';

/**
 * Приводит к строке
 *
 * @export
 * @param {IMicrofrontendIdentity} { location, version }
 * @return {*}
 */
export function identityToString({ mcs, version }: IMicrofrontendIdentity) {
  return `${mcs}#${version}`;
}
