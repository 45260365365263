import { GenericError } from './genericError';
import { ITypedErrorDescription } from './types';

/**
 * Ошибка "Ничего не найдено".
 *
 * @example
 *
 * function foo(params) {
 *    if (!('bar' in params)) {
 *      throw new NotFoundError({
 *        message: 'Bar not Found!', domain: 'foo()',
 *      });
 *    }
 * }
 *
 * @export
 * @class NotFoundError
 * @extends {GenericError}
 */
export class NotFoundError extends GenericError {
  public constructor({ message, domain, details }: ITypedErrorDescription) {
    super({
      type: 'NotFoundError',
      message,
      domain,
      details,
    });

    Object.setPrototypeOf(this, NotFoundError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
