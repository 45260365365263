import { ConfigurationError, NotFoundError } from '@cian/peperrors/shared';

import type { IBrowserRegistry } from '../browser/internal/registry/types';
import type { IManifest } from '../shared/manifest/types';

import { Registry } from './Registry';

declare global {
  interface Window {
    _cian_mf_registry: IBrowserRegistry | undefined;
    _cian_mf_registry_manifest: IManifest | undefined;
  }
}

function init() {
  if (window._cian_mf_registry) {
    throw new ConfigurationError({
      message: 'Registry already initialized',
      domain: 'mf-registry.cdn.init',
    });
  }

  const manifest = window._cian_mf_registry_manifest;

  if (!manifest) {
    throw new NotFoundError({
      message: 'Registry manifest not found',
      domain: 'mf-registry.cdn.init',
    });
  }

  const instance = new Registry();

  instance.init(manifest);

  window._cian_mf_registry = instance;
}

init();
