export function setAdvancedTimeout<T>(fn: () => T, ms: number) {
  let timeout: ReturnType<typeof setTimeout>;

  const promise = new Promise<T>((resolve, reject) => {
    timeout = setTimeout(() => {
      try {
        resolve(fn());
      } catch (e) {
        reject(e);
      }
    }, ms);
  });

  return {
    promise,
    clear: () => {
      clearTimeout(timeout);
    },
  };
}
