/* eslint-disable @typescript-eslint/no-explicit-any */

import { makeWebComponentName } from '../../../shared/webComponent/makeWebComponentName';

import type { IMicrofrontendIdentity, IUIRuntimeManifest } from '../../../shared/types';
import type { IUIRuntime, IUIRuntimeImage } from '../../../browser/types';

export class UIRuntime implements IUIRuntime {
  public readonly path: string;
  public readonly tag: string;

  public constructor(
    image: IUIRuntimeImage,
    identity: IMicrofrontendIdentity,
    path: string,
    manifest?: IUIRuntimeManifest,
  ) {
    const tag = manifest?.tag || makeWebComponentName(identity, path);
    customElements.define(tag, image.constructor);

    this.path = path;
    this.tag = tag;
  }
}
