import { GenericError } from './genericError';
import { ITypedErrorDescription } from './types';

/**
 * Ошибка валидации. Должна использоваться, когда аргументы не проходят валидацию.
 *
 * @example
 *
 * function fooHandler(req) {
 *    if (!req.header('X-RealUserId') || !req.header('X-Foo')) {
 *      throw new ValidationError({ message: 'Validation Error!', domain: 'fooHandler', details: {
 *        'X-Foo': req.header('X-Foo'),
 *        'X-RealUserId': req.header('X-RealUserId'),
 *      } });
 *    }
 * }
 *
 * @export
 * @class ValidationError
 * @extends {GenericError}
 */
export class ValidationError extends GenericError {
  public constructor({ message, domain, details }: ITypedErrorDescription) {
    super({
      type: 'ValidationError',
      message,
      domain,
      details,
    });

    Object.setPrototypeOf(this, ValidationError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
