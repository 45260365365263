import { ConfigurationError, TimeoutError, NotFoundError, ValidationError } from '@cian/peperrors/shared';

import type { IMicrofrontendManifest } from '../../shared/manifest/types';
import type { IMicrofrontendIdentity } from '../../shared/microfrontend/identity/types';
import type { IBrowserRegistry } from '../../browser/internal/registry/types';

import type {
  IBrowserChildMicrofrontend,
  ITimeoutOptions,
  IUIRuntime,
  IAPIRuntime,
  IRegistryMicrofrontend,
  IRegistryMicrofrontendOptions,
} from '../../browser/types';
import type { IGetUIRuntimeOptions, TAsset } from '../../shared/types';

import { setAdvancedTimeout } from '../helpers/timeout/setAdvancedTimeout';

import { UIRuntime } from './runtime/UIRuntime';
import { APIRuntime } from './runtime/APIRuntime';

interface IChildrenVersionMap {
  [location: string]: string;
}

interface IMicrofrontendDependencies {
  manifest: IMicrofrontendManifest;
  children: IMicrofrontendIdentity[];
  registry: IBrowserRegistry;
}

export enum EMicrofrontendStatus {
  Failed,
  Pending,
  Loading,
  Starting,
  Living,
}

export class Microfrontend implements IRegistryMicrofrontend, IBrowserChildMicrofrontend {
  public status: EMicrofrontendStatus = EMicrofrontendStatus.Pending;

  private startPromiseResolve: () => void;
  private startPromise = new Promise<void>(resolve => {
    this.startPromiseResolve = resolve;
  });

  private registry: IBrowserRegistry;
  private readonly manifest: IMicrofrontendManifest;
  private readonly childrenVersions: IChildrenVersionMap;

  /**
   * В ключах path
   */
  private ui = new Map<string, IUIRuntime>();
  private api: IAPIRuntime | void;

  public constructor({ manifest, children, registry }: IMicrofrontendDependencies) {
    this.registry = registry;
    this.manifest = manifest;

    this.childrenVersions = makeChildrenVersionsMap(children);
  }

  public get identity(): Readonly<IMicrofrontendIdentity> {
    return this.manifest.identity;
  }

  public get isLiving() {
    return this.status === EMicrofrontendStatus.Living;
  }

  public get isLoading() {
    return this.status === EMicrofrontendStatus.Loading;
  }

  public get isLoaded() {
    return this.status >= EMicrofrontendStatus.Starting;
  }

  public get assets(): Readonly<TAsset[]> {
    return this.manifest.assets;
  }

  public start({ runtimes: { ui, api }, path }: IRegistryMicrofrontendOptions) {
    const { identity, manifest } = this;

    if (ui) {
      if (this.ui.has(path)) {
        throw new ValidationError({
          message: `UI-runtume ${path} already started`,
          domain: `mf-registry.cdn.Microfrontend.start`,
          details: {
            path,
            manifest: JSON.stringify(manifest),
          },
        });
      }

      const manifestUI = manifest.runtimes?.ui?.find(m => m.path === path);

      this.ui.set(path, new UIRuntime(ui, identity, path, manifestUI));
    }

    if (api && !this.api) {
      this.api = new APIRuntime(api, identity);
    }

    this.checkLiving();
  }

  public async ensure({ timeout: timeoutMs = 10000 }: ITimeoutOptions = {}): Promise<this> {
    const timeout = setAdvancedTimeout(() => {
      let notInStatus = 'loaded';

      if (this.isLoaded) {
        notInStatus = 'started';
      }

      throw new TimeoutError({
        message: `Microfrontend not ${notInStatus} within ${timeoutMs}ms`,
        domain: 'mf-registry.cdn.Microfrontend.load',
      });
    }, timeoutMs);

    await Promise.race([this.startPromise, timeout.promise]).finally(() => {
      timeout.clear();
    });

    return this;
  }

  public getUIRuntime({ path }: IGetUIRuntimeOptions): IUIRuntime {
    const runtime = this.ui.get(path);

    if (runtime) {
      return runtime;
    }

    this.assertLivingRuntime();

    throw new NotFoundError({
      message: `UI-runtime ${path} not specified`,
      domain: `mf-registry.cdn.Microfrontend.getUIRuntime`,
      details: {
        identity: JSON.stringify(this.identity),
      },
    });
  }

  public getAPIRuntime(): IAPIRuntime {
    if (this.api) {
      return this.api;
    }

    this.assertLivingRuntime();

    throw new NotFoundError({
      message: `API-runtime not specified`,
      domain: `mf-registry.cdn.Microfrontend.getAPIRuntime`,
      details: {
        identity: JSON.stringify(this.identity),
      },
    });
  }

  public getChild(mcs: string) {
    const child = this.registry.getMicrofrontend({
      mcs,
      version: this.getChildVersion(mcs),
    });

    return child;
  }

  private checkLiving() {
    const everyUIRuntimeIsStarted = this.manifest.runtimes?.ui?.every(r => this.ui.has(r.path));

    if (!everyUIRuntimeIsStarted) {
      return;
    }

    this.status = EMicrofrontendStatus.Living;

    this.startPromiseResolve();
  }

  private getChildVersion(mcs: string) {
    const { childrenVersions } = this;
    const version = childrenVersions[mcs];

    if (!version) {
      throw new ConfigurationError({
        message: `Microfrontend ${mcs} is not specified in children`,
        domain: 'mf-registry.cdn.Microfrontend.getChildVersion',
        details: {
          mcs,
          childrenVersions: JSON.stringify(childrenVersions),
        },
      });
    }

    return version;
  }

  private assertLivingRuntime() {
    if (!this.isLiving) {
      throw new ConfigurationError({
        message: `Microfrontend should be started before runtime getting`,
        domain: `mf-registry.cdn.Microfrontend.assertLivingRuntime`,
        details: {
          identity: JSON.stringify(this.identity),
        },
      });
    }
  }
}

function makeChildrenVersionsMap(children: IMicrofrontendIdentity[]): IChildrenVersionMap {
  return children.reduce((result, child) => {
    result[child.mcs] = child.version;

    return result;
  }, {} as IChildrenVersionMap);
}
