import { identityToString } from '../microfrontend/identity/toString';

import type { IMicrofrontendIdentity } from '../microfrontend/types';

export function makeWebComponentName(identity: IMicrofrontendIdentity, path: string) {
  return `cian-mf-${identityToString(identity)}-${path}`
    .toLowerCase()
    .replace(/[^a-z\d-]/g, '-')
    .replace(/(^-+)|(-+$)/g, '');
}
