import { IErrorDetails, IErrorDescription } from './types';

/**
 * Базовый класс ошибки. Должен использоватья для создания типизированных ошибок.
 *
 * @export
 * @class GenericError
 * @extends {Error}
 */
export class GenericError extends Error {
  public readonly details: IErrorDetails = {};
  public readonly type: string;
  public readonly domain: string;

  public constructor({ message, type, domain, details }: IErrorDescription) {
    super(message);

    Object.setPrototypeOf(this, GenericError.prototype);

    this.type = type;
    this.domain = domain;

    this.name = type;

    if (details) {
      this.details = details;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GenericError);
    } else {
      this.stack = new Error().stack;
    }
  }

  /**
   * Печать детализации ошибки.
   * Внутри происходит сриализация details.
   *
   * @returns string детали ошибки в виде строки
   * @memberof GenericError
   */
  public printDetails() {
    return JSON.stringify(this.details, null, 2);
  }

  /**
   * Конвертация ошибки в данные.
   * Метод может быть полезен при пробрасывании информации от ошибок более низкого уровня в высокоуровневые ошибки
   */
  public toJSON() {
    return {
      type: this.type,
      domain: this.domain,
      message: this.message,
      details: this.details,
    };
  }
}
