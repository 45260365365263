import { GenericError } from './genericError';
import { ITypedErrorDescription } from './types';

/**
 * Ошибка конфигурации. Должна использоваться при конфигурации чего-либо.
 *
 * @example
 * const val = config.get('val');
 *
 * if (!val) {
 *  throw new ConfigurationError({ message: 'Configuration error!', domain: 'Foo#constructor' });
 * }
 *
 * @export
 * @class ConfigurationError
 * @extends {GenericError}
 */
export class ConfigurationError extends GenericError {
  public constructor({ message, domain, details }: ITypedErrorDescription) {
    super({
      type: 'ConfigurationError',
      message,
      domain,
      details,
    });

    Object.setPrototypeOf(this, ConfigurationError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConfigurationError);
    } else {
      this.stack = new Error().stack;
    }
  }
}
